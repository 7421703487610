export default {
    BACKEND_SERVER: 'http://localhost:5000',
    REGION: 'eu-central-1',
    USER_POOL_ID: 'eu-central-1_pR4TqwKw8',
    USER_POOL_APP_CLIENT_ID: 'le82ts9s32nco62e9uq1osjid',
    IDENTITY_POOL_ID: 'eu-central-1:8e6df299-e060-49d6-b4c0-65df508d4940',
    WIRING_DIAGRAM_BUCKET: 'ta-wiring-diagram-rdp-staging',
    WIRING_DIAGRAM_BULK_UPLOAD_BUCKET: 'ta-wiring-diagram-rdp-staging',
    JWT_EXPIRE_IN: '1h',
    USER_POOL_DOMAIN: 'wiring-diagram-user-pool-staging.auth.eu-central-1.amazoncognito.com',
    APP_DIRECT_URL: 'http://localhost:3000/',
    AZURE_REDIRECT_URL: 'http://localhost:3000/user-management/blank.html',
    IDENTITY_PROVIDER: 'TecAllianceConnectID',
    AZURE_APP_ID: 'b50840c8-8278-44b8-b161-973723e7df22',
    TENANT_ID: '91262959-85ec-4b1c-b7d3-7c3aac19c595',
    USER_POOL_GROUPS: {
        ADMIN: 'Admins_staging',
        USER: 'eu-central-1_pR4TqwKw8_TecAllianceConnectID'
    },
    AZURE_GROUP_ID: '76983e0a-126f-44b3-97d2-9478a08ef8ac',
    USE_SSO: true,
    SHOW_API_DOCUMENTATION: false,
}
